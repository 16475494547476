<template lang="pug">
Layout
  layout-sider(
    :width="550"
    :collapsible="false"
    className="sider-main")
    div(:style="{margin: '30px auto 0', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '357px', height:'100vh'}")
      div.container(
        v-if="state === 'email-form'",
        :style="{margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height:'70%'}"
        )
        logo(
          className="logo",
          mode="login",
          :customStyle="{ marginTop: '70px', marginBottom: '10px', maxHeight: '215px' }")
        div(:style="{marginBottom:'30px' }")
          AppH2(
            content="Ingresa tu correo de usuario, recibirás un correo de confirmación para recuperar tu "
            className="regular-container")
          AppH2(
            content="contraseña."
            className="regular-color")
        a-form(
            :form="form"
            @submit.prevent="onSendEmail"
          )
          a-form-item
            app-text(
              content="Correo electrónico:"
              className="regular-spaced-light inside-container")
            a-input(v-decorator="['email',{ rules: [{required: true, message: 'El correo electrónico es necesario'}, {type: 'email', message: 'Ingresa un correo válido'}] }]")            
            app-text(
              v-show="error"
              className="regular-error nano"
              :content="error")
          app-button(
            :loading="loading"
            type="primary"
            label="Enviar correo"
            className="block"
            htmlType="submit")
      div.container(
        v-if="state === 'successfully-email-request'",
        :style="{margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height:'70%'}"
        )
        logo
        AppH2(
            content="El correo de confirmación llegará en unos instantes, y solo será válido por 15 minutos."
            className="regular-container")
        app-button(
          :handler="goBack"
          type="primary"
          label="Reenviar"
          className="block"
          htmlType="submit")
      div.container(
        v-if="state === 'password-form'",
        :style="{margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height:'70%'}"
        )
        logo
        div
          AppH2(
            content="Cambio de contraseña"
            className="regular-container")
        a-form(
            :form="form"
            @submit.prevent="changePassword"
          )
          a-form-item(:validate-status=" !errorPassword1 ? 'success' : 'error' ",:help="errorPassword1")
            app-text(
              content="Contraseña:"
              className="regular-spaced-light inside-container")
            a-input(v-decorator="['password1',{initialValue:''}]",type="password")
          a-form-item(:validate-status=" !errorPassword2 ? 'success' : 'error' ",:help="errorPassword2")
            app-text(
              content="Confirmar Contraseña"
              className="regular-spaced-light inside-container")
            a-input(v-decorator="['password2',{initialValue:''}]",type="password")
          app-button(
            :loading="loading"
            type="primary"
            label="Cambiar contraseña"
            className="block"
            htmlType="submit")
  banner-image
</template>
<script>
// //Import atoms components
// import AppImage from "@/components/atoms/AppImage.vue";

// //Import organisms components
import { AuthService } from "@/common/api.service";
import LayoutSider from "@/components/organisms/LayoutSider.vue";
import AppH2 from "@/components/atoms/AppH2.vue";
import AppText from "@/components/atoms/AppText.vue";
import AppButton from "@/components/atoms/AppButton.vue";

//Import atoms components
import AppImage from "@/components/atoms/AppImage.vue";
//Import molecules
import BannerImage from "@/components/molecules/Banner.vue";
//Import organisms components
import Layout from "@/components/organisms/Layout.vue";
import Logo from "@/components/molecules/Logo.vue";
// //Import customComponents from this component
// import SiderBarLeft from "@/components/customComponents/LoginClient/SiderBarLeft.vue";

export default {
  name: "PasswordRecovery",
  components: {
    Layout,
    AppImage,
    LayoutSider,
    AppH2,
    AppText,
    AppButton,
    Logo,
    BannerImage
  },
  data() {
    let form = this.$form.createForm(this, {
      name: "coordinated"
    });
    return {
      form: form,
      loading: false,
      state: "email-form",
      error: null,
      errorPassword1: null,
      errorPassword2: null
    };
  },
  created() {
    let recovery_token = this.$route.query.recovery_token;
    if (recovery_token) {
      AuthService.verifyRecoveryToken(recovery_token)
        .then(() => {
          this.state = "password-form";
        })
        .catch(() => {
          this.$message.error("El token expiró o es invalido");
          this.state = "email-form";
        });
    }
  },
  methods: {
    async changePassword() {
      this.form.validateFields(async (err, values) => {
        this.loading = true;
        this.errorPassword1 = null;
        this.errorPassword2 = null;
        let { password1, password2 } = values;
        if (password1.trim().length == 0)
          this.errorPassword1 = "Campo requerido";
        else if (password1.length < 8)
          this.errorPassword1 =
            "La contraseña debe tener al menos 8 caracteres";
        if (password2.trim().length == 0)
          this.errorPassword2 = "Campo requerido";
        else if (password1.length < 8)
          this.errorPassword2 =
            "La contraseña debe tener al menos 8 caracteres";
        if (
          !this.errorPassword1 &&
          !this.errorPassword2 &&
          password1 !== password2
        ) {
          this.errorPassword1 = "Las contraseñas no coinciden";
        }
        if (!this.errorPassword1 && !this.errorPassword2) {
          try {
            await AuthService.setNewPassword(
              password1,
              password2,
              this.$route.query.recovery_token || "INVALID"
            );
            this.$message.success("Nueva contraseña establecida con éxito");
            this.$router.push({
              name: "LoginComercial"
            });
          } catch (exp) {
            let errors = exp.response.data;
            if (
              errors.non_field_errors.some(error =>
                [
                  "El token de invitación ha expirado.",
                  "Invalid token"
                ].includes(error)
              )
            ) {
              this.$message.error("El token expiró o es invalido");
              this.state = "email-form";
            } else {
              this.errorPassword1 = "Contraseña débil";
            }
          }
        }
        this.loading = false;
      });
    },
    goBack() {
      this.state = "email-form";
    },
    async onSendEmail() {
      this.form.validateFields(async (err, values) => {
        this.error = null;
        if (err) return;
        try {
          this.loading = true;
          await AuthService.getRecoveryToken(values.email);
          this.state = "successfully-email-request";
        } catch (err) {
          this.error = "No existe usuario con este correo";
        }
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.sider-text-container {
  width: 357px;
}
.form-link {
  cursor: pointer;
  font-size: 16px;
  color: var(--link-color);
  letter-spacing: 1px;
  margin: 0;
}
.form-link:hover {
  text-decoration: underline;
}

.ant-input {
  background: var(--container-color) !important;
  border: 0 !important;
  border-bottom: 3px solid var(--primary-color) !important;
  color: var(--container-text-color);
  border-radius: 0;
}
.top-decorator {
  height: 3px;
  background-color: var(--primary-color);
  width: 60px;
  margin-bottom: 15px;
}

.container {
  transform: translateY(-20%);
}
</style>
